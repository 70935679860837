import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import tickMark from "../../assets/images/tick-mark.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loggly from "../ErrorsHandler/Logger";
import { cameraToggle, updateCamera, updateisShowError } from "../../Store/Actions/PublishStreamAction";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { usePubNub } from "pubnub-react";

// set pros
// set pros
interface props {
  isSetupPopup?: boolean;  isSetting : boolean, mediaStreamAvailability: boolean
}

const VideoInputSelector = ({ isSetupPopup, isSetting, mediaStreamAvailability  }: props) => {
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const obj: PublishStream = stateData?.publishStreamObj?.obj;
    const pubnub = usePubNub();
      //Manage update redux state in event listener
      const deviceIdRef = useRef(stateData?.publishStreamObj?.cameraId);
      const deviceIdList = useRef<MediaDeviceInfo[]>([]);
    
      const channelName = `${
        "Chat." +
        stateData.publishStreamObj.webinarid +
        "." +
        stateData.publishStreamObj.sessionId
      }`;
      useEffect(() => {
        deviceIdRef.current = stateData?.publishStreamObj?.cameraId; // Update ref whenever state changes
      }, [stateData?.publishStreamObj?.cameraId]);
    
      useEffect(() => {
        deviceIdList.current = videoDevices; // Update ref whenever state changes
      }, [videoDevices]);
      //end
      const videoToggle = async () => {
       
        await obj?.streamCameraUpdate(false);
    
        dispatch(cameraToggle(false));
    
        pubnub
          .signal({
            message: {
              camera: false,
            },
            channel: channelName,
          })
          .catch((error) => {
            console.error("Error sending mute signal:", error);
            console.error("Error status:", error.status); // Log the status code or status object
            console.error("Error message:", error.message);
          });
      };
    
    
      
  function isFirefox() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("firefox") > -1;
  }

  useEffect(() => {
    let deviceChangeTimeout: NodeJS.Timeout;

    const gotDevices = (deviceInfos: MediaDeviceInfo[]) => {
      try {
        
        const videoInputList: MediaDeviceInfo[] = deviceInfos.filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(videoInputList);
        if(!(deviceIdList.current.length == 0 && isSetting == true)){ 
        if (
          stateData?.publishStreamObj?.cameraId === undefined &&
          videoInputList.length > 0
        ) {
          const device = videoInputList[0];
          if (device.deviceId) {
            handleSelectChange(device.deviceId, device.label);
          } else {
            console.error("Device ID is undefined.");
          }
          // handleSelectChange(
          //   videoInputList[0].deviceId,
          //   videoInputList[0].label
          // );
        }
        else{
          // handleSelectChange(
          //   videoInputList[0].deviceId,
          //   videoInputList[0].label
          // );
        }
      }
      } catch (error) {
        console.error("Error processing video input devices:", error);
        loggly.push({
          tag: "error",
          message: "An error occurred while processing video input devices",
          error: error.message,
        });
      }
    };

    let errorCounter = 0; // Counter for consecutive errors

    const handleDeviceChange = () => {
      let retryAttempted = false;
    
      const attemptDeviceChange = async () => {
        try {
          if (isFirefox()) {
            clearTimeout(deviceChangeTimeout);
          }
    
          /**
           * this part does not work in firefox
           * Firefox does not expose device IDs unless getUserMedia() has been called at least once.
              This is a privacy feature to prevent fingerprinting.
           */
          //    navigator.mediaDevices
          // .enumerateDevices()
          // .then(gotDevices)
          // .catch((error) => {
          //   console.error("Error enumerating video input devices:", error);
          //   loggly.push({
          //     tag: "error",
          //     message:
          //       "An error occurred while enumerating video input devices",
          //     error: error.message, 
          //   });
          // });

          /**
           * this part does not get wider resolution from browser in chrome and firefox
           */
          // await navigator.mediaDevices.getUserMedia({ video: true });
          // const devices = await navigator.mediaDevices.enumerateDevices();
          // gotDevices(devices);

          
          const tempStream = await navigator.mediaDevices.getUserMedia({ video: true });
          tempStream.getTracks().forEach(track => track.stop()); // Stop temporary stream

          const devices = await navigator.mediaDevices.enumerateDevices();
          gotDevices(devices); // Your function to handle devices

          const constraints = {
            video: {
              width: { ideal: 1280 }, // Set ideal width for higher resolution
              height: { ideal: 720 }, // Set ideal height
              frameRate: { ideal: 30 }
            }
          };
      
          const finalStream = await navigator.mediaDevices.getUserMedia(constraints);
          console.log("Final stream resolution:", finalStream.getVideoTracks()[0].getSettings());
    
          // Reset error counter on success
          errorCounter = 0;
        } catch (error) {
          console.error("Error handling video input device change:", error);
    
          // Increment error counter
          errorCounter++;
          console.log("errorCounter", errorCounter);
    
          if (errorCounter >= 2 || error.message === "Device in use" || isFirefox()) {
            dispatch(updateisShowError(true)); // Show error after two consecutive errors
            videoToggle();
          }
    
          loggly.push({
            tag: "error",
            message: "An error occurred while handling video input device change",
            error: error.message,
          });

          if(error.message==="Device in use"){
            retryAttempted = true;

          }
    
          if (!retryAttempted ) {
            retryAttempted = true; // Set the flag to true
            console.log("Retrying device change...");
            attemptDeviceChange(); // Retry once
          }
        }
      };
    
      attemptDeviceChange(); // Initial attempt
    };
    

    handleDeviceChange();

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {

      
      if (isFirefox()) {
        clearTimeout(deviceChangeTimeout);
      }
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, []);

  const handleSelectChange = (cameraId: string, cameraLabel: string) => {
    try {
      checkCameraStreamAvailability(cameraId).then(isAvailable => {
        console.log("Camera stream available:", isAvailable);
        if(isAvailable) {
          dispatch(updateCamera(cameraId, cameraLabel));  
        } else {
          // alert('selected cam is not available');   
          // dispatch(updateisShowError(true)) 
          // videoToggle();
          if(stateData?.publishStreamObj?.cameraId !== undefined) {
            // alert(cameraLabel + ' feed is not available at the moment');
            
            dispatch(updateCamera(stateData?.publishStreamObj?.cameraId, stateData?.publishStreamObj?.cameraLabel));
          } else {
            dispatch(updateCamera(cameraId, cameraLabel));  
          }
        }
        
        
      });
      // loggly.push({ tag: "Camera", message: "Camera changed" });
    } catch (error) {
      // alert('not able to capture stream');
      // dispatch(updateisShowError(true)) 
      // videoToggle();
      console.error("Error dispatching camera ID:", error);
      // loggly.push({
      //   tag: "error",
      //   message: "An error occurred while dispatching camera ID",
      //   error: error.message,
      // });
    }
  };

  async function checkCameraStreamAvailability(selectedCameraId,trials=1) {
    try {
      // Retrieve a list of available media devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      
      // Find the selected camera by device ID
      const selectedCamera = devices.find(device => device.kind === "videoinput" && device.deviceId === selectedCameraId);
      
      if (!selectedCamera) {
        console.error("Selected camera not found.");
        return false;
      }
  
      // Try to access the camera stream
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedCameraId }
      });
  
      // Check if video track is enabled and can be published
      const videoTrack = stream.getVideoTracks()[0];
      const isStreamAvailable = videoTrack && videoTrack.readyState === "live";
  
      // Stop the stream after checking
      videoTrack.stop();
  
      return isStreamAvailable;
    } catch (error) {
      console.error("Error accessing the selected camera stream:", error);
      if(error.message) {
        console.log("trial", trials);

        if(trials <= 2) {
          return checkCameraStreamAvailability(selectedCameraId, trials + 1);
        }
         
        dispatch(updateisShowError(true)) 
        videoToggle();
        }
      return false;
    }
  }

  return (
    <div className="ew-form-field">
      <label htmlFor="video-dropdown">
        Camera{" "}
        <span>
          <img src={tickMark} alt="" />
        </span>
      </label>
      <span className="label-sub-heading">
        {isSetupPopup
          ? "Please select your desired camera to be used in the webinar"
          : "Select your preferred camera to conduct this webinar"}
      </span>
      <Select
        id="dropdown"
        menuPlacement="auto"
        options={videoDevices.map((device) => ({
          value: device.deviceId,
          label: device.label || `Camera ${videoDevices.length + 1}`,
        }))}
        value={
          stateData?.publishStreamObj?.cameraId === undefined
            ? {
                value: videoDevices[0]?.deviceId,
                label: videoDevices[0]?.label,
              }
            : {
                value: stateData?.publishStreamObj?.cameraId,
                label: stateData?.publishStreamObj?.cameraLabel,
              }
        }
        onChange={(selectedOption) => {
          if (selectedOption) {
             handleSelectChange(selectedOption.value, selectedOption.label);
          }
        }}
      />
      {!mediaStreamAvailability && (
        <p style={{ color: "red" }}>Not able to access selected media device</p>
      )}
    </div>
  );
};

export default VideoInputSelector;
