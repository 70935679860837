import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { PresentorStream } from "./PresentorStream";
import { CSSProperties } from "react";
import Loader from "../Loader/Loader";
import { adminJoinUrl, baseUrl, customCurrentEnv, dolbyRecordingUrl, easyWbinarBaseURL } from "../../config";
import ErrorMessage from "../CommonComponents/ErrorMessage";
// import ClipLoader from "react-spinners/ClipLoader";
// import BeatLoader from "react-spinners/BeatLoader";

export const Admin = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  //Loader
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [params, setParams] = useSearchParams();
  const [sid, setsid] = useState(params.get("sid"));
  const [did, setdid] = useState(params.get("did"));
  const [webinarid, setwebinarid] = useState(params.get("webinar_id"));
  const [userName, setuserName] = useState("");
  const [token, settoken] = useState("");
  const [streamName, setstreamName] = useState("");
  const [subscription_token, setsubscription_token] = useState("");
  const [pubnub_access_token, setpubnub_access_token] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //end

  // Backend API call start
  function updateReduxState(
    name,
    email,
    token,
    subscription_token,
    streamName,
    attendeeLink,
    moderatorLink,
    presentorLink,
    sessionId: string,
    userUUID,
    isAttendeeList,
    isChatPanel,
    isPrivateChat,
    isPublicChat,
    event_name,
    adminUserId,
    webinar_version
  ) {
    dispatch({
      type: "ADD_USERNAME",
      payload: {
        ...stateData?.publishStreamObj,
        userName: name,
        userEmail: email,
        userUUID: userUUID,
        sid: sid,
        did: did,
        sessionId: sessionId,
        webinarid: webinarid,
        token: token,
        subscribeToken: subscription_token,
        streamName: streamName,
        attendeeLink: attendeeLink,
        moderatorLink: moderatorLink,
        presentorLink: presentorLink,
        isAdmin: true,
        isAttendeeList: isAttendeeList,
        isChatPanel: isChatPanel,
        isPrivateChat: isPrivateChat,
        isPublicChat: isPublicChat,
        event_name: event_name,
        adminUserId: adminUserId || 0,
        webinar_version: webinar_version
      },
    });
  }

  useEffect(() => {
    // localStorage.setItem(
    //   "ew_jwt_token",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjcyNDA1NTcsImV4cCI6MTcyNzI2OTM1NywiZGF0YSI6eyJ1c2VybmFtZSI6InRlc3QgdXNlciIsInVzZXJfaWQiOjc5MzksImV4cG9ydF9saW1pdCI6IjIwMCJ9fQ.hiHLoyCaCrZHxTTAuzYiS4w3k-GL4EAFiRvf5KFSFFI"
    // );
    const ew_jwt_token = localStorage.getItem("ew_jwt_token");

    (async () => {
      setAuthToken(ew_jwt_token);
      try {
        const res = await postRequest(adminJoinUrl, {
          webinar_id: webinarid,
          did: did,
          sid: sid,
        });

        if (res?.status == "error") {
          setErrorMessage(res.message);
          setLoader(false);
          return;
        }

       
        setuserName(res.data.name);
        settoken(res.data.token);
        setstreamName(res.data.streams[0].streamName);
        setsubscription_token(res.data.subscription_token);
        setpubnub_access_token(res.data.pubnub_accessToken);
        updateReduxState(
          res.data.name,
          res.data.email,
          res.data.token,
          res.data.subscription_token,
          res.data.streams[0].streamName,
          res.data.attendee_link,
          res.data.moderator_link,
          res.data.presenter_link,
          res.data?.session_id,
          res.data.pn_user_id,
          res.data.permissions.attendeeList,
          res.data.permissions.chatPanel,
          res.data.permissions.privateChat,
          res.data.permissions.publichChat,
          res.data?.webinar_details?.event_name,
          res.data.adminUserId,
          res.data.webinar_version
        );
        setLoader(false);
        
        try {
            const getEcs = localStorage.getItem("start_ecs" + webinarid + '-' + sid);            
            if (getEcs) {
                const ecsData = JSON.parse(getEcs);
                
                if (ecsData.status === 'started') {
                    console.log("Recording already started");
                    return;
                }
            } 
        
            // If not started, proceed with recording
            const response = await postRequest(
                `${dolbyRecordingUrl}`,
                {
                    action: "startecs",
                    webinarId: webinarid,
                    sessionId: sid,
                    confAlias: `${webinarid}_${sid}_${customCurrentEnv}`,
                    recorderLink: `${easyWbinarBaseURL}${baseUrl}/recorder?stream_name=${res.data.streams[0].streamName}`
                }
            );
        
            if (!response || response.statusCode !== 201 || response.status !== "success" || !response.data?.taskId) {
                throw new Error("Invalid response: taskId is missing or response is not successful");
            }
        
            console.log("response ::: ", response);
        
            // Store the status as "started" and save the timestamp only if it doesn't exist
            const ecsData = {
                status: "started",
                timestamp: getEcs ? JSON.parse(getEcs).timestamp : Date.now() // Keep existing timestamp if exists
            };        
            localStorage.setItem("start_ecs" + webinarid + '-' + sid, JSON.stringify(ecsData));
        
        } catch (error) {
            console.log("Unable to start recording ::: ", error);
        }
      } catch (error) {
        console.log("error ::: ", error);
        setErrorMessage(error.message);
        setLoader(false);
        // window.location.href = `https://test-v2.easywebinar.com/v2/events/${sid}/schedule`;
      }
    })();
  }, []);
  // Backend API call end

  //Loader end
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  //let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#000000");
  // end

  return (
    <>
      {loader && (
        <div>
          <Loader />
        </div>
      )}
      {!loader && !errorMessage && (
        <PresentorStream
          userName={userName}
          streamName={streamName}
          token={token}
          subscription_token={subscription_token}
          pubnub_accessToken={pubnub_access_token}
        />
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
