import React from "react";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
export const SidebarLoader = () => {
  return (
    <div className="flex flex-center loader-bg">
      {/* <div className='blur-bg'></div> */}
      <div className="flex flex-center section-loader">
        {/* <div className="circle-loader"></div> */}
        <img src={blueCircleLoader} alt="" />
        <span className="dark-text">Loading...</span>
      </div>
    </div>
  );
};
