import React from "react";
import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useSelector } from "react-redux";
import refreshDarkIcon from "../../assets/images/refresh-dark-icon.png";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";

interface props {
  name: string;
  screenSharedUsername: string;
  streams: any;
  sourceId: string;
  muteAllStream: boolean;
  remoteTrackSources: any;
}

export const ScreenShareForAttendeeScreen = ({
  name,
  streams,
  sourceId,
  screenSharedUsername,
  muteAllStream,
  remoteTrackSources
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  const videoRef = useRef<HTMLVideoElement>(null);

  function stopScreenShare() {
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    obj.stopSreenShare();
  }

  // Initialise video
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [resolution, setResolution] = useState(null);
  const messageRef = useRef(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isIphone = /iPhone/i.test(navigator.userAgent);



  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }

    
    if (isMobile) {
      setTimeout(() => {
        getCurrentResolution();
      }, 5000);
      setTimeout(() => {
        adjustVideoResolution();
      }, 8000);
    }
  }, [remoteTrackSources]);



  function getCurrentResolution() {
    if (!streams) return;
  
    const streamTracks = streams?.getVideoTracks();
    if (!streamTracks || streamTracks.length === 0) {
      console.error("No video tracks found.");
      return;
    }
  
    const track = streamTracks[0];
    const settings = track.getSettings();
    console.log("Current Resolution:", settings.width, "x", settings.height);
    setResolution({ width: settings.width, height: settings.height });  
  }
  function adjustVideoResolution() {
    if (!streams) return;
  
    const streamTracks = streams?.getVideoTracks();
    if (!streamTracks || streamTracks.length === 0) {
      console.error("No video tracks found.");
      return;
    }
  
    const track = streamTracks[0];
    const capabilities = track.getCapabilities(); // Get camera capabilities
    const previousSettings = track.getSettings(); // Get current resolution
  
    console.log("Capabilities:", capabilities);
    console.log("Previous Settings:", previousSettings);
  
    // Use previousSettings if capabilities are missing (common in screen sharing)
    const maxWidth = capabilities.width?.max || previousSettings.width || window.innerWidth;
    const maxHeight = capabilities.height?.max || previousSettings.height || window.innerHeight;
  
    // Use actual aspect ratio if available, otherwise assume 16:9
    const aspectRatio = previousSettings.aspectRatio || 16 / 9;
  
    // Target resolution based on window size
    let targetWidth = Math.min(maxWidth, Math.floor(window.innerWidth * 0.8)); // 80% of window width
    let targetHeight = Math.floor(targetWidth / aspectRatio); // Maintain aspect ratio
  
    // Ensure height fits within the window
    if (targetHeight > window.innerHeight * 0.8) {
      targetHeight = Math.floor(window.innerHeight * 0.8);
      targetWidth = Math.floor(targetHeight * aspectRatio); // Adjust width to maintain ratio
    }
  
    // Final constraints ensuring we don't exceed available max resolution
    const constraints = {
      width: Math.min(targetWidth, maxWidth),
      height: Math.min(targetHeight, maxHeight),
    };
  
    console.log("Applying Constraints:", constraints);
  
      track.applyConstraints(constraints)
        .then(() => {
          console.log("Resolution adjusted to:", constraints);
          setResolution({ width: constraints.width, height: constraints.height });
        })
        .catch(err => console.error("Error applying constraints:", err));
  }
  
 

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      setTimeout(() => {
        videoRef.current.srcObject = streams;
        videoRef.current.play().catch((err) => console.warn("Autoplay error:", err));
      }, 500);
    }
  };

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  
     const intervalRef = useRef<NodeJS.Timeout | null>(null);
   
     const intervalStart = () => {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          if (videoRef.current) {
            console.log("Refreshing video...");
    
            // Remove existing stream
            videoRef.current.srcObject = null;
    
            // Delay before setting the new stream (important!)
            setTimeout(() => {
              videoRef.current.srcObject = streams;
              videoRef.current.play().catch((err) => console.warn("Autoplay error:", err));
            }, 500); // Small delay
          }
        }, 3000);
      }
     };
     
     const handleLoadStart = () => {
        if(isIphone){ 
          intervalStart();    
        }
       };
     
       const handleWaitStart = () => {
        if(isIphone){ 
          intervalStart();    
        }
       };

   
    
   
     const handlePlaying = () => {
      setLoadingVideo(false);
       // Stop the interval if video is playing
       if (intervalRef.current) {
         clearInterval(intervalRef.current);
         intervalRef.current = null;
       }
     };


     useEffect(() => {   
      const handleVisibilityChange = () => {
        const pageVisibility = document.visibilityState;        
        if (pageVisibility === 'visible') {
          if(isIphone){ 
            intervalStart();    
          }
        }       
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };    
    }, []);
    
  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      {/* {resolution?.width && resolution?.height && (
        <div className="resolution-indicator">
          {resolution.width} x {resolution.height}
        </div>
      )} */}
      <div className="ew-video-wrapper">
        {loadingVideo && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )}

        <div className="presenter-options-dropdown" ref={dropdownRef}>
          <span
            className="flex flex-center"
            onClick={handlePresenterDropdownClick}
          >
            <img src={verticallyDotsIcon} alt="" />
          </span>
          {presenterMenuOpen && (
            <ul>
              <li className="flex" onClick={reLoad}>
                <img src={refreshDarkIcon} alt="" />
                <span>Refresh</span>
              </li>
            </ul>
          )}
        </div>
        {screenSharedUsername != name && (
          <div className="ew-live-video">
            <video
              controls={false}
              autoPlay
              loop
              muted={muteAllStream}
              ref={videoRef}
              id={sourceId}
              onError={() => {
                videoRef.current?.error &&
                  console.error(
                    `Video player error: ${videoRef.current?.error}`
                  );
              }}
              // onLoadStart={() => setLoadingVideo(true)}
              // onPlaying={() => {
              //   setLoadingVideo(false);
              //   messageRef.current = false;
              // }}
              onLoadStart={handleLoadStart}
              onPlaying={handlePlaying}
              onStalled={() => {
                console.error("Video is on stalled");
              }}
              // onWaiting={() => setLoadingVideo(true)}
              onWaiting={handleWaitStart}
              playsInline
            ></video>
          </div>
        )}
        <div className="presenter-name flex flex-center">
          {screenSharedUsername}
        </div>
      </div>
    </div>
  );
};
