import { useEffect, useState } from "react";
import emptyPollsIcon from "../../assets/images/empty-polls.svg";
import closeIcon from '../../assets/images/close-icon.svg';
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  updateActivePollStatus,
  updateIsSideBar,
} from "../../Store/Actions/PublishStreamAction";
import { attendeePollsViewUrl, lambdaPollAnsUrl, pubnubResultsUrl } from "../../config";
import buttonLoader from "../../assets/images/button-loader.gif";

// Types and Interfaces
interface PollOption {
  id: number;
  val: string;
}

interface PollData {
  poll_id: number;
  status: string;
  poll_question: string;
  options: PollOption[];
  pollData: string
}

interface Props {
  attendeeData: any,
  createPollHandler: () => void;
  updatePollHandler: (value: any) => void;
}

interface PollResult {
  [key: number]: number;
}
interface FinalResult {
  pollId: string;
  results: PollResult;
}

export const PollsAttendee = ({ createPollHandler, updatePollHandler, attendeeData }: Props) => {

  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const pubnubobj = usePubNub();
  const [activePoll, setActivePoll] = useState<PollData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | number>("");
  const [pollsResponse, setPollsResponse] = useState<any>()
  const [pollResult, setPollResult] = useState<FinalResult>({
    pollId: "",
    results: {}
  });
  const [showVoteResult, setShowVoteResult] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [loading,setLoading]=useState(false)


  const [resultChannelSubscribe, setResultChannelSubscribe] = useState(null);

  useEffect(() => {
  
    if (resultChannelSubscribe !== null) {
      pubnubobj.subscribe({
        channels: [resultChannelSubscribe]
      });
    }

    pubnubobj.addListener({
      message: function (event) {
        const { message } = event;
       
        // Assuming message contains the poll ID and other relevant details
        if (message['type'] == 'result_publish') {
          if (activePoll.poll_id && activePoll['poll_id'] == message['pollId']) {
            let pollResultUpdated = {
              pollId: message['pollId'],
              results: message['results']
            };
            setPollResult(pollResultUpdated);
          }
        }

      }
    });

  }, [resultChannelSubscribe]);

  useEffect(() => {
    dispatch(updateActivePollStatus(false));
    const globalPollChannel = "Polls." + stateData.publishStreamObj.webinarid + "." + stateData.publishStreamObj.sessionId;
    pubnubobj.subscribe({ channels: [globalPollChannel] });

    pubnubobj.addListener({
      message: function (event) {
        const { message } = event;
        if (message['type'] == 'activate_poll') {
          // Fetch poll details using the received poll ID
          fetchPollDetails('event_captured');
          dispatch(updateActivePollStatus(true));

        }
        if (message['type'] == 'deactivate_poll') {
          setActivePoll(null);
          dispatch(updateActivePollStatus(false));
          if (resultChannelSubscribe !== null) {
            pubnubobj.unsubscribe({
              channels: [resultChannelSubscribe]
            });
          }
        }
      }
    });

    const fetchPollDetails = async (type) => {
      setShowLoader(true)
      try {
        const res = await pubnubobj.objects.getChannelMetadata({ channel: globalPollChannel });
        setPollsResponse(res?.data)
        setShowLoader(false)

        if (res.data && res.data.custom.activePoll) {
          const activePollId = res.data.custom.activePoll;


          try {
            var pollViewCheck = window.localStorage.getItem("pollview" + stateData.publishStreamObj.userUUID + activePollId);
            if (pollViewCheck === null) {
              window.localStorage.setItem("pollview" + stateData.publishStreamObj.userUUID + activePollId, 'poll_viewed');

              let payload = {
                webinar_id: stateData.publishStreamObj.webinarid,
                sid: stateData.publishStreamObj.sessionId,
                poll_id: activePollId,
                attendee_key: attendeeData?.attendee_data?.key
              }

              axios({
                method: "post",
                url: attendeePollsViewUrl,
                data: payload,
              });

            }


          } catch (error) {
            console.log("pollRes published lambda errro ::", error)
          }

          const activePollChannel = "Polls." + stateData.publishStreamObj.sessionId + "." + activePollId;
          setShowLoader(true)
          const pollRes = await pubnubobj.objects.getChannelMetadata({ channel: activePollChannel });
          setShowLoader(false)
          if (pollRes.data) {
           
            const pollOptions: PollOption[] = typeof pollRes.data.custom.options === 'string'
              ? JSON.parse(pollRes.data.custom.options)
              : [];
            const poll_id = Number(pollRes.data.custom.poll_id);
            setActivePoll({
              poll_id: poll_id,
              status: pollRes.data.status,
              poll_question: pollRes.data.description,
              options: pollOptions,
              pollData: String(pollRes.data.custom.pollData)
            });
          }
          setShowVoteResult(false);

          const votePostedChannel = "votes." + stateData.publishStreamObj.sessionId + "." + activePollId;
          // const resultPostedChannel = "results." + stateData.publishStreamObj.sessionId + "." + activePollId;

          setShowLoader(true)
          pubnubobj.objects.getMemberships({
            uuid: stateData.publishStreamObj.userUUID,
            filter: '(channel.id == "' + votePostedChannel + '")',
            include: {
              customChannelFields: true,
              customFields: true,
            },
          })
            .then((response) => {
              setShowLoader(false)
              if (response.data.length) {
                publishResults(votePostedChannel, null, activePollId);
                setShowVoteResult(true)
              }
            })
            .catch((error) => {
              console.log('metadata does not exist');
              setShowLoader(false)
            });
        }
      } catch (error) {
        console.error("Error fetching poll data:", error);
        setShowLoader(false)
      }
    };

    // Fetch metadata only once after component mounts
    fetchPollDetails('initial');
  }, [stateData.publishStreamObj.webinarid, stateData.publishStreamObj.sessionId]);

  // Handle option change
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const publishResults = async (voteChannel: String, pollResultId: any, activePollId) => {
    try {

      const resultChannel = "results." + stateData?.publishStreamObj?.sessionId + "." + activePollId;

      
      setResultChannelSubscribe(resultChannel);
      setShowLoader(true)
      const res = await axios({
        method: "post",
        url: pubnubResultsUrl,
        data: {
          uuid: stateData.publishStreamObj.userUUID,
          channel: voteChannel,
          resultChannel: resultChannel
        },
        headers: { "Content-Type": "application/json" }
      });
      
   
      setShowLoader(false)
      const updatedObj = {
        ...res.data, // Copy all properties from the original object
        results: {
          ...res.data.results, // Copy all properties from the results object
          ...(pollResultId !== null && { // Check if pollResultId is not null
            [pollResultId]: (res?.data?.results?.[pollResultId] ?? 0) + 1// Increment the value for the matching key
          })
        }
      };
    
      setPollResult(updatedObj)
      setShowVoteResult(true)
    } catch (e) {
      console.error("Error publishing results:", e);
      // throw e; // Optionally rethrow the error if you want to handle it further up the call stack
    }
  };

  // Handle poll submission
  const submitPoll = (selectedOption) => {

    try {
      setLoading(true)
      /**
       * message will be sent to votes channel which further will be handled by publish function on pubnub account
       */
      const voteChannel = "votes." + stateData.publishStreamObj.sessionId + "." + activePoll.poll_id;
  
      const resultChannel = "results." + stateData.publishStreamObj.sessionId + "." + activePoll.poll_id;
  
      /**
       * before publishing if channel and uuid exists in pubnub
       */
      // Step 1: Verify if UUID exists
      pubnubobj.objects.getUUIDMetadata({
        uuid: stateData.publishStreamObj.userUUID
      }).then((response) => {
        // If UUID exists, proceed to check the votes channel
        checkVotesChannel();
      })
        .catch((error) => {
          // If UUID does not exist, create it
          pubnubobj.objects.setUUIDMetadata({
            uuid: stateData.publishStreamObj.userUUID,
            data: {
              custom: { role: 'attendee' }
            }
          }).then((response) => {
            // After creating the UUID, proceed to check the votes channel
          
            checkVotesChannel();
          })
            .catch((error) => {
              console.log("Error creating UUID Metadata:", error);
            });
        })
  
      // Function to check if votes channel exists
      const checkVotesChannel = () => {
        pubnubobj.objects.getChannelMetadata({
          channel: voteChannel
        }).then((response) => {
          if (response.data) {
            // If the channel exists, proceed to publish the vote
            publishVote();
          } else {
            // If the channel does not exist, set the channel metadata
            setVotesChannelMetadata();
          }
        })
          .catch((error) => {
            console.log("Error checking votes channel:", error,error?.status, error?.errorData);
            // If the channel does not exist, set the channel metadata
            setVotesChannelMetadata();
          });
      };
  
      // Function to set votes channel metadata
      const setVotesChannelMetadata = () => {
        pubnubobj.objects.setChannelMetadata({
          channel: voteChannel,
          data: {
            name: `Votes for Poll ${activePoll.poll_id}`,
            description: `Channel for votes on poll ${activePoll.poll_id}`,
            custom: { poll_id: activePoll.poll_id }
          }
        }).then((response) => {
          // After setting the channel metadata, proceed to publish the vote
          publishVote();
        })
          .catch((error) => {
            console.log("Error creating Votes Channel Metadata:", error);
          });
      };
  
      // Function to publish the vote
      const publishVote = () => {
        pubnubobj.publish({
          channel: voteChannel,
          message: { "option_id": selectedOption },
          storeInHistory: false
        }, (status, response) => {
          if (status.error) {
            console.log('Publish Error:', status);
            // alert(status.errorData.message);
            publishResults(voteChannel, null, activePoll.poll_id);
          } else {
           
            postAnsToLambda();
            publishResults(voteChannel, parseInt(selectedOption), activePoll.poll_id);
            // Subscribe to the result channel after publishing the vote
  
            setResultChannelSubscribe(resultChannel);
          }
        });
      };
  
      const postAnsToLambda = async () => {
        try {
          let headers = {
            "content-type": "application/json",
            Authorization: `${attendeeData?.Authorization}`,
            "X-EW-WEBINAR-ID": `${stateData.publishStreamObj.webinarid}`,
            "X-EW-KEY": `${attendeeData?.attendee_data?.key}`,
            "X-EW-TIMESTAMP": `${attendeeData?.anstimestamp}`,
            // "X-EW-TIMESTAMP": `${new Date()
            //   .toISOString()
            //   .replace("T", " ")
            //   .substring(0, 19)}`,
          };
  
          const webinar_time = attendeeData?.attendee_data?.webinar_time;
          const webinar_date = attendeeData?.attendee_data?.webinar_date;
          let webinarDateTime = new Date(`${webinar_date}T${webinar_time}`);
  
          let lambdaPayload = {
            webinar_id: stateData.publishStreamObj.webinarid,
            poll_id: activePoll.poll_id,
            session_id: stateData.publishStreamObj.sessionId,
            attendee_id: attendeeData?.attendee_data?.registration_id_pk, // attendee_data.registration_id_p
            webinar_date_time: webinarDateTime.toISOString().split('T')[0] + ' ' + webinarDateTime.toTimeString().split(' ')[0],
            is_replay: "0",
            attendee_key: attendeeData?.attendee_data?.key, // attendee_data.key
            datetime: new Date().toISOString().replace("T", " ").substring(0, 19), // current utc time
            option_id: selectedOption,
            global_session_id: attendeeData?.attendee_data?.global_session_id, // // attendee_data.global_session_id
            owner_id: attendeeData?.meta?.owner_id, // meta.owner_id
            poll_tracked_at: new Date()
              .toISOString()
              .replace("T", " ")
              .substring(0, 19), // when firstly user seen the poll
            poll_desc: activePoll.pollData, // from polls response
            webinar_version: "1",
          };
  
          const res = await axios({
            method: "post",
            url: lambdaPollAnsUrl,
            data: lambdaPayload,
            headers: headers,
          });

          setLoading(false)
        } catch (e) {
          console.error("Error publishing results to lambda:", e);
          // throw e; // Optionally rethrow the error if you want to handle it further up the call stack
        }
      };
      
    } catch (error) {
      setLoading(false)
    }

   

   



    // pubnubobj.objects.getMemberships({
    //   uuid: stateData.publishStreamObj.userUUID,
    //   filter: '(channel.id == "' + voteChannel + '")',
    //   include: {
    //       customChannelFields: true,
    //       customFields: true,
    //   },
    // })
    // .then((response) => {
    //   if (response.data.length > 0 && response.data[0].custom && response.data[0].custom.cast === true) {
    //     alert('user already given answer');
    //   }
    //   else {
    //     pubnubobj.objects.setMemberships({
    //         uuid: stateData.publishStreamObj.userUUI,
    //         // custom: { "cast":"true" },
    //         channels: [
    //             { 
    //                 id: voteChannel, 
    //                 custom: {
    //                     "option_id": selectedOption,
    //                     "cast": true,
    //                 }
    //             },
    //         ],
    //         include: {
    //             customChannelFields: true,
    //             customFields: true,
    //         },
    //     }).then((res) => {
    //       // alert('set member shipdata done');
    //     }).catch((error) => {
    //       // alert('set member shipdata done');
    //     })
    //   }
    // }).catch((error) => {
    //   console.log(error);
    //   alert('something went wrong, unable to set membership data')
    // });
  };

  const totalVotes = pollResult && pollResult.results ? Object.values(pollResult.results).reduce((acc, votes) => acc + votes, 0) : 0;

  return (
    <div className="Polls-area">
      <div className="sidebar-header">
        <a
          href="#"
          onClick={() => {
            dispatch(updateIsSideBar(false));
          }}
        >
          <img src={closeIcon} alt="" />
        </a>
        Polls
      </div>
      <div className="polls-content-area">
        <div className="polls-inner-container thin-scrollbar">
          {showLoader ? (
            <div className="flex flex-center loader-bg">
              <div className="flex flex-center section-loader">
                <img src={blueCircleLoader} alt="" />
                <span>Loading...</span>
              </div>
            </div>
          ) : activePoll && showVoteResult ? (
            <div>
              <h4 className="tabs-inner-heading">Active Polls</h4>
              <div className="polls-info-main">
                <h3>
                  {activePoll.poll_question} <span></span>
                </h3>
                <ul>
                  {activePoll.options.map((option, opindex) => {
                    const voteCount = pollResult.results?.[option.id] ?? 0;
                    const percentage =
                      totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
                    return (
                      <li className="flex" key={option.id || opindex}>
                        <p>
                          {option.val}
                          <div className="polls-percntage">
                            <span style={{ width: `${percentage}%` }}></span>
                          </div>
                        </p>
                        <span>{percentage.toFixed(2)}% Voted</span>
                      </li>
                    );
                  })}
                </ul>
                {/* <div className="flex votes-info">
                  <span className="flex flex-center total-views">
                    {totalVotes} voted
                  </span>
                </div> */}
              </div>
            </div>
          ) : activePoll && !showVoteResult ? (
            <>
              <h4 className="tabs-inner-heading">Active Polls</h4>
              <div className="polls-info-main attendee-polls-preview">
                <h3>{activePoll.poll_question}</h3>
                <ul>
                  {activePoll.options.map((option, index) => (
                    <li
                      key={index}
                      className={`flex ${
                        selectedOption === option.id ? "selected" : ""
                      }`}
                    >
                      <label className="radio-input">
                        <input
                          type="radio"
                          value={option.id}
                          checked={selectedOption === option.id}
                          onChange={handleOptionChange}
                        />
                      </label>
                      <p>
                        <span>{option.val}</span>
                      </p>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  // className={
                  //   selectedOption !== ""
                  //     ? "btn primary-button"
                  //     : "btn disabled-button"
                  // }
                 className= {`${loading ? "loading" : ""} ${selectedOption!==""?"btn primary-button":"btn disabled-button"}`}
                  onClick={() => submitPoll(selectedOption)}
                >
                  Submit
                  <span className="btn-loader">
                        <img src={buttonLoader} alt="" />
                      </span>
                </button>
              </div>
            </>
          ) : (
            <div className="empty-area-msg flex-center">
              <img src={emptyPollsIcon} alt="No Active Polls" />
              <h4>Polls</h4>
              <p>You’ve no active polls right now.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

