import React from 'react'
const emojiMap = {
    smiley_face: "😊",
    thumbs_up: "👍",
    heart: "❤️",
    laugh: "😂",
    clap: "👏",
  };
const ChatMessageReaction = ({ messageId, onReaction }) => {
    return (
        <div className="emoji-reaction-bar" style={{
          fontSize: "15px", // ✅ Smaller emoji size
          cursor: "pointer",
          border: "none",
          background: "transparent",
          padding: "",
          display:"flex",
          alignContent:"center",
          justifyContent:"center",
       
        }}>
          {Object.entries(emojiMap).map(([key, emoji]) => (
            <span key={key} className="emoji-btn" onClick={() => onReaction(messageId, key)}>
              {emoji}
            </span>
          ))}
        </div>
      );
}

export default ChatMessageReaction
