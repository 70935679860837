import React from "react";
import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import { Spinner } from "@chakra-ui/react";
import refreshDarkIcon from "../../assets/images/refresh-dark-icon.png";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { getScreenShareChannelName } from "../../libs/signal/signalEnum";
import { usePubNub } from "pubnub-react";
import { toast } from "react-toastify";
interface props {
  name: string;
  screenSharedUsername: string;
  streams: any;
  sourceId: string;
  newVideoUrl:string;
  setEnableScreenSharebutton?:(val:boolean)=>void;
  setIsGenericMessage?:(val:boolean)=>void;
  setNewVideoUrl:(val:string)=>void
}

export const LocalVideoShare = ({
  name,
  streams,
  sourceId,
  screenSharedUsername,
  newVideoUrl,
  setEnableScreenSharebutton,
  setIsGenericMessage,
  setNewVideoUrl
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const pubnubobj = usePubNub();
  const obj: PublishStream = stateData?.publishStreamObj?.obj;
  const videoRef = useRef<HTMLVideoElement>(null);
  const screenShareChannelName = getScreenShareChannelName(
    stateData.publishStreamObj.webinarid,
    stateData.publishStreamObj.sessionId
  );
  function stopScreenShare() {
    setNewVideoUrl("")

    setIsGenericMessage(true)
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    obj.stopSreenShare();
    videoRef.current=null
    setEnableScreenSharebutton(true)
  }

  // Initialise video
  const [loadingVideo, setLoadingVideo] = useState(true);
  const messageRef = useRef(false);

//   function reloadTimeInterval() {
//     if (messageRef.current == true) reLoad();
//     setTimeout(() => {
//       reloadTimeInterval();
//     }, 5000);
//   }


useEffect(() => {
   
  let  VideoElement=videoRef.current
  VideoElement.crossOrigin = 'anonymous'; 
  VideoElement=videoRef.current

  let audioContext;
  if (VideoElement) {
    VideoElement.crossOrigin = 'anonymous'; // Set video source
    videoRef.current.crossOrigin = 'anonymous';
    VideoElement.src = newVideoUrl // Set video source
   

        const playVideo = () => {
          VideoElement?.play()
            .then(() => {
              pubnubobj.signal({
                message: {
                  screen: true,
                },
                channel: screenShareChannelName,
              });
             
              console.log("Video is playing successfully!");
            }
             ) // Add any additional actions here
            .catch(error => {
              stopScreenShare()
              console.error("Error playing video:", error); // Handle any errors while trying to play
            });
        };
      
   
    // Add event listener for when video is loaded and ready to play
    VideoElement.addEventListener('loadeddata', playVideo);
    VideoElement.addEventListener('click', () => {
      if ( videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
  });



  VideoElement.onloadeddata = () => {
    try {
      if(!videoRef.current){
        return
      }

      console.log("inside here")
      const videoStream1 = ( VideoElement as any).captureStream?.()
      // ? ( videoRef.current as any).captureStream() // Standard method
      // : ( videoRef.current as any).mozCaptureStream // Firefox-specific method
      // ? ( videoRef.current as any).mozCaptureStream() 
      // : ( videoRef.current as any).webkitCaptureStream // Older Chrome versions
      // ? ( videoRef.current as any).webkitCaptureStream() 
      // : null;
      if (!videoStream1) {
        // Fallback to using a canvas
        const canvas = document.createElement('canvas');
        canvas.width =  VideoElement?.videoWidth;
        canvas.height = VideoElement?.videoHeight;

        const context = canvas?.getContext('2d');

        // Define a function to draw the video frame to the canvas at regular intervals
        const drawVideoToCanvas = () => {
          context?.drawImage(VideoElement, 0, 0, canvas.width, canvas.height);
          requestAnimationFrame(drawVideoToCanvas); // Repeat at the next frame
        };

        drawVideoToCanvas(); // Start drawing video frames to the canvas

        const videoStream = canvas?.captureStream(); // Capture the stream from the canvas

        // Get audio from video element
         audioContext = new (window.AudioContext )();
        const audioSource = audioContext.createMediaElementSource( VideoElement);
        const mediaStreamDestination = audioContext.createMediaStreamDestination();
        audioSource.connect(mediaStreamDestination);
        audioSource.connect(audioContext.destination); // Connect audio to the output

        const audioStream = mediaStreamDestination.stream;

        // Combine video and audio streams
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        // Use the Dolby API to send the combined stream
        obj.startLocalVideoShare(combinedStream); // Replace with actual Dolby method
       
      } else {
        // Capture video stream directly if supported
        // const videoStream = (videoElement as any).captureStream();

       

        // Use the Dolby API to send the combined stream
        obj.startLocalVideoShare(videoStream1); // Replace with actual Dolby method
       
      }
      
    } catch (error) {
      stopScreenShare()
    
      
    }
      
    };


    // Cleanup: remove event listener on component unmount or when videoURL changes
    return () => {
     console.log("unmounteddddddddddddddddddddddd")
     setNewVideoUrl("")
     VideoElement.removeEventListener('loadeddata', playVideo);
     VideoElement.removeEventListener('click', ()=>{});
      audioContext?.close(); 
    };
  }
  


}, [newVideoUrl]); 






//   useEffect(() => {
//     if (videoRef.current) {
//       videoRef.current.srcObject = null;
//       videoRef.current.srcObject = streams;
//     }
//   }, []);

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      setTimeout(() => {
        videoRef.current.srcObject = streams;
        videoRef.current.play().catch((err) => console.warn("Autoplay error:", err));
      }, 500);
    }
  };

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        {loadingVideo && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              {/* <div className="circle-loader"></div> */}
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )}
        <div className="presenter-options-dropdown" ref={dropdownRef}>
          <span
            className="flex flex-center"
            onClick={handlePresenterDropdownClick}
          >
            <img src={verticallyDotsIcon} alt="" />
          </span>
          {presenterMenuOpen && (
            <ul>
              <li className="flex">
                <img src={refreshDarkIcon} alt="" />
                <span>Refresh</span>
              </li>
            </ul>
          )}
        </div>
        {/* {screenSharedUsername != name && ( */}
          <div className="ew-live-video">
            <video
             onKeyDown={(e) => e.stopPropagation()}
            //  disablePictureInPicture
             controlsList=" nofullscreen  nodownload  noplaybackrate "
             disableRemotePlayback
             disablePictureInPicture
              controls
              autoPlay
              loop
              ref={videoRef}
              id={sourceId}
              onError={() => {
                videoRef.current?.error &&
                  console.error(
                    `Video player error: ${videoRef.current?.error}`
                  );
                  stopScreenShare()
              }}
              onLoadStart={() => setLoadingVideo(true)}
              onPlaying={() => {
                setLoadingVideo(false);
                messageRef.current = false;
              }}
              onStalled={() => {
                console.error("Video is on stalled");
              }}
              onWaiting={() => setLoadingVideo(true)}
              playsInline
            ></video>
          </div>
        {/* )} */}
        <div className="presenter-name flex flex-center">
          {screenSharedUsername}
        </div>
      </div>
    </div>
  );
};
