export const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const mins = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };


 export  const getUrlForEmail = async (channelName, email,obj) => {
    try {
      // Fetch the existing metadata
      const result = await obj.objects.getChannelMetadata({ channel: channelName });
      let currentUrls = [];
  
      // Check if the `urls` array exists in the metadata
      if (result.data && result.data.custom && result.data.custom.urls) {
        currentUrls = JSON.parse(result?.data?.custom?.urls?.toString()); // Parse to convert string to array
      }
  
      // Find the URL that contains the specific email
      const matchingUrl = currentUrls?.find(url => url.includes(email));
  
      if (matchingUrl) {
        console.log(`Email "${email}" found in URL: ${matchingUrl}`);
      } else {
        console.log(`Email "${email}" not found in any URLs.`);
      }
  
      return matchingUrl || null; // Return the matching URL or null if not found
    } catch (error) {
      console.error(`Failed to check email in URLs for channel ${channelName}:`, error);
      return null;
    }
  };



  export const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return true;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return true;
    }
    return false;
  };

  export async function publishEWEvent(eventInfo: any) {
    try {
      // return;
      const idToken = localStorage.getItem("idToken");
      const metadataToken = localStorage.getItem("metadataToken");
      const decodedMetdata = decodeJWT(metadataToken)
      // console.log('decodedMetdata', decodedMetdata)
      if(!decodedMetdata) return
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      const companies = decodedMetdata.companies
      if(!companies) return
      let currentCompany = companies.find(c =>  c.app_id == "easy_webinar")
      if(!currentCompany) currentCompany = companies[0]
      const response = await fetch('https://w42st912za.execute-api.us-east-1.amazonaws.com/dev/usage/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CognitoToken': idToken
        },
        body: JSON.stringify({
          ...eventInfo,          
          app_name: "EASY WEBINAR",
          subscription_id: currentCompany.subscription.id,
          customer_id: currentCompany.id,
          created_by: "EW_LIVE_ROOM",
          updated_by: "EW_LIVE_ROOM"
        })
      });
  
      // Check if the response is OK (status 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json(); // Parse the JSON response
      console.log(data); // Log the response data
    } catch (error) {
      console.error('Error:', error); // Handle errors
    }
  }

  export async function updateEWEvent(eventInfo: any) {
    try {
      const response = await fetch('https://w42st912za.execute-api.us-east-1.amazonaws.com/dev/usage/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...eventInfo,          
          app_name: "EASY WEBINAR",
          created_by: "EW_LIVE_ROOM",
          updated_by: "EW_LIVE_ROOM"
        })
      });
  
      // Check if the response is OK (status 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json(); // Parse the JSON response
      console.log(data); // Log the response data
    } catch (error) {
      console.error('Error:', error); // Handle errors
    }
  }

  export function getSubscriptionAndCustomerId() {
    try {
      const metadataToken = localStorage.getItem("metadataToken");
      const decodedMetdata = decodeJWT(metadataToken)
      // console.log('decodedMetdata', decodedMetdata)
      if(!decodedMetdata) return {
        subscription_id: "NA",
        customer_id: "NA",
      }
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      const companies = decodedMetdata.companies
      if(!companies)  return {
        subscription_id: "NA",
        customer_id: "NA",
      }
      let currentCompany = companies.find(c =>  c.app_id == "easy_webinar")
      if(!currentCompany) currentCompany = companies[0]
  
      return {
        subscription_id: currentCompany.subscription.id,
        customer_id: currentCompany.id,
      }

    } catch (error) {
      console.error('Error:', error); // Handle errors
      return {
        subscription_id: "NA",
        customer_id: "NA",
      }
    }
  }

  export const EVENT_ENUMS = {
    webinar_started: {
      event_name: "Webinar Started",
      event_id: "webinar_started",
      record_usage: true,
      event_domain: "Webinar Management",
    },
    webinar_ended:  {
      event_name: "Webinar Ended",
      event_id: "webinar_ended",
      record_usage: false,
      event_domain: "Webinar Management",
    },
    attendee_joined:  {
      event_name: "Attendee Joined",
      event_id: "attendee_joined",
      record_usage: true,
      event_domain: "Attendee Management",
    }
  }

  const decodeJWT = (metadataToken: string) => {
    const ew_jwt_token = metadataToken
    let decodedPayload = null
    if(ew_jwt_token) {
      const parts = ew_jwt_token.split('.');
      if (parts.length !== 3) {

      }  
      const payload = parts[1];
      decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')))
    }
    return decodedPayload
  }