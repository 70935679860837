import { dolbyRecordingUrl, exitGreenRoomUrl } from "../config";
import { postRequest } from "../libs/APIs/apiCall";


export const exitGreenRoomAPI = async (sid, webinarId) => {
    try {
        const res = await postRequest(exitGreenRoomUrl, {
            sid: sid,
            webinar_id: webinarId,
        });
        return res;
    } catch (error) {
        console.error("Error in exitGreenRoom:", error);
        throw error;
    }
};


export const exitECStaskAPI = async (sid, webinarid) => {
    try {
        localStorage.removeItem("start_ecs" + webinarid + '-' + sid);
        
        const response = await postRequest(
            `${dolbyRecordingUrl}`,
            {
                action: "exitecs",
                webinarId: webinarid,
                sessionId: sid
            }
        );
        return response;
        
    } catch (error) {
        console.log("unable to start recording ::: ", error);
    }
};

export const checkliveroomStatus = async (sid, webinarid) => {
    try {        
        const response = await postRequest(
            `${dolbyRecordingUrl}`,
            {
                action: "verify-recording",
                webinarId: webinarid,
                sessionId: sid
            }
        );
        return response;
        
    } catch (error) {
        console.log("unable to start recording ::: ", error);
    }
};